import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/ModalWrapper';
import Input from 'components/Input';
import AmountPad from 'components/Amount/AmountPad';
import useFormField from 'hooks/useFormField';
import CustomerDetails from 'components/CustomerDetails';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import { STEPS } from '../../Claim.useWrapper';
import * as Styles from './AddAndClaim.styles';

const AddAndClaim = (props) => {
  const [t] = useTranslation();
  const {
    customer: { firstName, lastName, phone, balance, gender, email, birthday, currentTier } = {},
    dataCaptureFlow,
    secondaryFieldsToDisplay = {},
    addClaimHandler,
    addCustomerHandler,
    step,
    gotoEditDetailsHandler,
    resetModalHandler,
    locale,
    isLoading,
  } = props;
  const [firstNameInput, onChangeFirstNameInput] = useFormField('');

  const renderCustomer = () => (
    <CustomerDetails
      Styles={Styles}
      customer={{
        firstName,
        lastName,
        phone,
        balance,
        gender,
        email,
        birthday,
        currentTier,
      }}
      dataCaptureFlow={dataCaptureFlow}
      secondaryFieldsToDisplay={secondaryFieldsToDisplay}
      gotoEditDetailsHandler={gotoEditDetailsHandler}
      locale={locale}
      amountLabel={t('balance')}
      popOverLinks={['edit', 'redeemAmount', 'redeemCampaign', 'add', 'deduct']}
    />
  );

  const renderNameError = (value) => {
    if (!value) return '';
    const regex = /^\s*([A-Za-z]{0,}([\\.,] |[-']| )?)+[A-Za-z]+\.?\s*$/;
    if (!regex.test(value))
      return <div css={Styles.nameError}>{t('please-enter-a-valid-name')}</div>;
    return '';
  };

  const renderCustomerInputForm = () => {
    const isAddCustomerCTAEnabled = firstNameInput;

    return (
      <>
        <div css={Styles.cusContent}>
          <div css={Styles.sectionDesc}>{t('enter-guest-details')}</div>
          <div css={Styles.cusDetailsWrapper}>
            <div css={Styles.inputWrapper}>
              <Input
                type="text"
                name="firstname"
                label={t('first-name')}
                onChange={onChangeFirstNameInput}
                autoFocus
              />
              {renderNameError(firstNameInput)}
            </div>
            <div css={Styles.entryWrapper}>
              <div css={Styles.entryLabel}>{t('phone-number')}</div>
              <div css={Styles.entryContent}>{formatNumber(phone)}</div>
            </div>
          </div>
        </div>
        <div css={Styles.cusFooter}>
          <button
            id="continue-button"
            css={isAddCustomerCTAEnabled ? Styles.CTA : Styles.disabledCTA}
            type="button"
            {...assignEventListener(
              isAddCustomerCTAEnabled
                ? () => addCustomerHandler({ firstName: firstNameInput, phone })
                : null
            )}
          >
            {t('continue')}
          </button>
        </div>
      </>
    );
  };

  const leftSectionToRender =
    step === STEPS.CLAIM_LOYALTY ? renderCustomer : renderCustomerInputForm;

  return (
    <div className="claim-loyalty-step">
      <ModalWrapper wrapperSelector={'.claim-loyalty-step'} resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.cusContainer}>{leftSectionToRender()}</div>
          <AmountPad
            Styles={Styles}
            header={t('enter-the-total-amount-spent')}
            currencyDenomination={locale.currencyDenomination}
            isAmountGreaterThanBalance={() => false}
            isAmountCTAEnabled={(number) => number.length >= 1}
            errMsg=""
            amountHandler={addClaimHandler}
            amountButton={t('add-credit')}
          >
            {step === STEPS.ADD_CUSTOMER ? <div css={Styles.overlayDiv} /> : null}
          </AmountPad>
          <div css={isLoading ? Styles.loadingOverlay : Styles.loadingOverlayHidden}>
            <ReactLoading type={'spin'} color={'#169A89'} width={50} />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

AddAndClaim.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    birthday: PropTypes.any,
    currentTier: PropTypes.object,
  }),
  dataCaptureFlow: PropTypes.string.isRequired,
  secondaryFieldsToDisplay: PropTypes.object,
  addClaimHandler: PropTypes.func,
  addCustomerHandler: PropTypes.func,
  gotoEditDetailsHandler: PropTypes.func,
  resetModalHandler: PropTypes.func,
  step: PropTypes.oneOf([STEPS.ADD_CUSTOMER, STEPS.CLAIM_LOYALTY]).isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
};

export default AddAndClaim;
