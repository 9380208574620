import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import history from 'util/router-history';
import { ERROR_TYPES } from 'util/errors';
import { getStepFromURL } from 'util/url';
import ROUTES from 'constants/routes';
import DATA_CAPTURE_FLOWS from 'constants/data-capture-flows';
import FIELD_OPTIONS from 'constants/data-field-options';
import REDEMPTION_SECURITY_OPTIONS from 'constants/redemption-security';
import { PartnerContext } from 'contexts/PartnerProvider';
import { AppContext } from 'contexts/AppProvider';
import { searchPhoneNumDebounced, updateCustomer as updateCustomerPATCH } from 'api/venue';
import {
  redeemAmount as redeemAmountPOST,
  submitOTP as submitOTPPOST,
  resendOTP as resendOTPPOST,
} from 'api/redeem';
import {
  fetchCampaignsByGuestId as fetchCampaignsByGuestIdGET,
  redeemCampaign as redeemCampaignPATCH,
} from 'api/guest';
import { addNoteToTransaction as addNoteToTransactionPOST } from 'api/notes';
// for dev purposes only
// import xinitialState from './mock-initial-state';

export const STEPS = {
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  REDEEM_CAMPAIGN: 'REDEEM_CAMPAIGN',
  REDEEM_CAMPAIGN_CONFIRM: 'REDEEM_CAMPAIGN_CONFIRM',
  REDEEM_CAMPAIGN_SUCCESS: 'REDEEM_CAMPAIGN_SUCCESS',
  REDEEM_AMOUNT: 'REDEEM_AMOUNT',
  DATA_ACQUISITION: 'DATA_ACQUISITION',
  VALIDATE_OTP: 'VALIDATE_OTP',
  VALIDATE_PASSWORD: 'VALIDATE_PASSWORD',
  VALIDATE_OTP_OR_PASSWORD: 'VALIDATE_OTP_OR_PASSWORD',
  REDEEM_SUCCESS: 'REDEEM_SUCCESS',
};

const ACTIONS = {
  RESET: 'RESET',
  SET_PHONE: 'SET_PHONE',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_PHONE_REMOVE_CUSTOMER: 'SET_PHONE_REMOVE_CUSTOMER',
  SET_AMOUNT: 'SET_AMOUNT',
  SET_RESEND_FLAG: 'SET_RESEND_FLAG',
  SET_OTP_ERROR: 'SET_OTP_ERROR',
  SET_DUPLICATE_EMAIL_STATUS: 'SET_DUPLICATE_EMAIL_STATUS',
  SET_REDEEMABLE_CAMPAIGNS: 'SET_REDEEMABLE_CAMPAIGNS',
  SELECT_CAMPAIGN_AND_GOTO_CONFIRM: 'SELECT_CAMPAIGN_AND_GOTO_CONFIRM',
  MARK_REDEEMABLE_CAMPAIGN_AS_REDEEMED_GOTO_REDEEM_CAMPAIGN_SUCCESS:
    'MARK_REDEEMABLE_CAMPAIGN_AS_REDEEMED_GOTO_REDEEM_CAMPAIGN_SUCCESS',
  RESET_REDEEMABLE_CAMPAIGN_GOTO_REDEEM_PHONE_STEP:
    'RESET_REDEEMABLE_CAMPAIGN_GOTO_REDEEM_PHONE_STEP',
  ENABLE_EDIT_DETAILS_MODE_GOTO_DATA_ACQ_STEP: 'ENABLE_EDIT_DETAILS_MODE_GOTO_DATA_ACQ_STEP',
  SET_ERROR: 'SET_ERROR',
  GOTO_STEP: 'GOTO_STEP',
  IS_PHONE_LOADING: 'IS_PHONE_LOADING',
  IS_LOADING: 'IS_LOADING',
};

const initialState = {
  step: STEPS.MOBILE_NUMBER,
  customer: {
    id: 0, // guestId
    loyaltyAccountId: 0,
    isFound: false,
    resendRequested: false,
    phone: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthday: null,
    email: '',
    balance: 0,
    totalClaimed: 0,
    totalRedeemed: 0,
    amountRedeemed: 0,
    lastTransactionId: 0,
    hasOTPError: false,
    hasDuplicateEmail: false,
    redeemableCampaigns: [],
    currentTier: {},
  },
  selectedRedeemableCampaign: undefined,
  selectedRedeemableCampaignRedeemed: false,
  editDetailsMode: false,
  errorMessage: '',
  error: null, // error: { name: ERROR_NAME , message: ERROR_MESSAGE } it's an error object
  nextRequestId: 1,
  displayedRequestId: 0,
  isPhoneLoading: {},
  idempotencyKey: '',
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.RESET:
      return initialState;
    case ACTIONS.SET_PHONE: {
      const prevState = { ...state };
      const customer = { ...prevState.customer, phone: action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_CUSTOMER: {
      const prevState = { ...state };
      const customer = {
        ...prevState.customer,
        isFound: true,
        ...action.payload,
      };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_PHONE_REMOVE_CUSTOMER: {
      const prevState = { ...state };
      const customer = { ...initialState.customer, phone: action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_AMOUNT: {
      const prevState = { ...state };
      const customer = { ...prevState.customer, ...action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_RESEND_FLAG: {
      const prevState = { ...state };
      const customer = { ...prevState.customer, resendRequested: true };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_OTP_ERROR: {
      const prevState = { ...state };
      const { hasOTPError, errorMessage } = action.payload;
      const customer = { ...prevState.customer, hasOTPError };
      return { ...prevState, customer, ...{ errorMessage } };
    }
    case ACTIONS.GOTO_STEP: {
      const prevState = { ...state };
      const { STEP: step, ...rest } = action.payload;
      return { ...prevState, step, ...rest };
    }
    case ACTIONS.SET_ERROR: {
      const prevState = { ...state };
      const { error, ...rest } = action.payload;
      return { ...prevState, error, ...rest };
    }
    case ACTIONS.SELECT_CAMPAIGN_AND_GOTO_CONFIRM: {
      const prevState = { ...state };
      const customer = { ...prevState.customer };
      const { redeemableCampaigns } = customer;
      const { campaignId } = action.payload;
      const selectedCampaign = redeemableCampaigns.find((c) => c.id === campaignId);

      return {
        ...prevState,
        selectedRedeemableCampaign: selectedCampaign,
        step: STEPS.REDEEM_CAMPAIGN_CONFIRM,
      };
    }
    case ACTIONS.MARK_REDEEMABLE_CAMPAIGN_AS_REDEEMED_GOTO_REDEEM_CAMPAIGN_SUCCESS: {
      const prevState = { ...state };
      const customer = { ...prevState.customer };
      const { redeemableCampaigns } = customer;
      const { campaignId } = action.payload;
      if (redeemableCampaigns.some((c) => c.id === campaignId)) {
        return {
          ...prevState,
          selectedRedeemableCampaignRedeemed: true,
          step: STEPS.REDEEM_CAMPAIGN_SUCCESS,
        };
      }

      throw new Error('This line should never be invoked — DEBUG WHY');
    }
    case ACTIONS.RESET_REDEEMABLE_CAMPAIGN_GOTO_REDEEM_PHONE_STEP: {
      const prevState = { ...state };

      return {
        ...prevState,
        selectedRedeemableCampaign: undefined,
        selectedRedeemableCampaignRedeemed: false,
        step: STEPS.MOBILE_NUMBER,
      };
    }
    case ACTIONS.SET_DUPLICATE_EMAIL_STATUS: {
      const prevState = { ...state };
      const { hasDuplicateEmail } = action.payload;
      const customer = { ...prevState.customer, hasDuplicateEmail };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_REDEEMABLE_CAMPAIGNS: {
      const prevState = { ...state };
      const { redeemableCampaigns } = action.payload;
      const customer = { ...prevState.customer, redeemableCampaigns };
      return { ...prevState, customer };
    }
    case ACTIONS.ENABLE_EDIT_DETAILS_MODE_GOTO_DATA_ACQ_STEP: {
      const prevState = { ...state };

      return {
        ...prevState,
        editDetailsMode: true,
        step: STEPS.DATA_ACQUISITION,
      };
    }
    case ACTIONS.IS_PHONE_LOADING: {
      const prevState = { ...state };
      const { isPhoneLoading } = action.payload;
      return {
        ...prevState,
        isPhoneLoading: { ...prevState.isPhoneLoading, ...isPhoneLoading },
      };
    }
    case ACTIONS.IS_LOADING: {
      const prevState = { ...state };
      return {
        ...prevState,
        isLoading: action.payload,
      };
    }
    default:
      throw new Error('Invalid action dispatched');
  }
};

const OTP_ERRORS = [
  ERROR_TYPES.InvalidOTPError,
  ERROR_TYPES.ExpiredOTPError,
  ERROR_TYPES.InvalidTransactionError,
];

/* Entire data control for redeem steps */
const useWrapper = () => {
  const {
    partner: { token, currentVenueId, currencyDenomination, phonePrefix, venueSettings = {} },
    partner,
  } = React.useContext(PartnerContext);

  const {
    setAppState,
    app: { shouldReloadAppOnPageIdle, globalCustomer = {} },
  } = React.useContext(AppContext);

  const initialStateOverride = { ...initialState };
  initialStateOverride.customer = {
    ...initialState.customer,
    ...globalCustomer,
  };

  // get current step
  if (globalCustomer.phone && globalCustomer.phone.length >= 3) {
    const initialStep = getStepFromURL();
    initialStateOverride.step = initialStep || STEPS.MOBILE_NUMBER;
  }

  const [state, dispatch] = React.useReducer(reducer, initialStateOverride);
  const {
    step,
    customer,
    errorMessage = '',
    selectedRedeemableCampaign,
    selectedRedeemableCampaignRedeemed,
    editDetailsMode,
    error,
    isPhoneLoading,
    idempotencyKey,
    isLoading,
  } = state;

  const locale = {
    currencyDenomination,
    phonePrefix,
  };

  const resetDataHandler = () => {
    if (shouldReloadAppOnPageIdle) {
      window.location.reload(true);
    } else {
      setAppState({ isAppIdle: true, globalCustomer: {} });
      dispatch({ type: ACTIONS.RESET });
    }
  };

  const resetAndRedirectToDefaultPageHandler = () => {
    setAppState({ globalCustomer: {} });
    dispatch({ type: ACTIONS.RESET });
    history.push(ROUTES.NAV);
  };

  const phoneNumberSearchHandler = async (phoneNum) => {
    try {
      // To avoid race condition: we here are adding a count for each api hit
      // and saving it to local scope (requestId), so after api response we check with the local count
      initialState.nextRequestId += 1;
      const requestId = initialState.nextRequestId;

      dispatch({
        type: ACTIONS.IS_PHONE_LOADING,
        payload: {
          isPhoneLoading: { [phoneNum]: true },
        },
      });

      const data = await searchPhoneNumDebounced({
        token,
        currentVenueId,
        phone: phoneNum,
      });

      dispatch({
        type: ACTIONS.IS_PHONE_LOADING,
        payload: {
          isPhoneLoading: { [phoneNum]: false },
        },
      });

      // To avoid race condition: we here are returing (no action taken)
      // if the api requested is older then the global requestid we have in (displayedRequestId)
      // else we change the global requestId to the current requestId.
      if (requestId < initialState.displayedRequestId) return;
      initialState.displayedRequestId = requestId;

      const {
        id,
        firstName,
        lastName = '',
        phone,
        email,
        gender,
        birthday,
        loyaltyAccount: {
          id: loyaltyAccountId,
          balanceAmount,
          cashbackAmount,
          redeemedAmount,
        } = {},
        currentTier = {},
      } = data;

      if ((firstName || lastName) && phone) {
        dispatch({
          type: ACTIONS.SET_CUSTOMER,
          payload: {
            id,
            loyaltyAccountId,
            firstName,
            lastName: lastName || '',
            phone,
            email,
            gender,
            birthday: birthday || '',
            balance: balanceAmount,
            totalClaimed: cashbackAmount,
            totalRedeemed: redeemedAmount,
            currentTier,
          },
        });

        setAppState({
          globalCustomer: {
            id,
            loyaltyAccountId,
            firstName,
            lastName: lastName || '',
            phone,
            email,
            gender,
            birthday: birthday || '',
            balance: balanceAmount,
            totalClaimed: cashbackAmount,
            totalRedeemed: redeemedAmount,
            currentTier,
          },
        });
      } else {
        // IF NOT FOUND - goto ADD_CUSTOMER step
        dispatch({
          type: ACTIONS.SET_PHONE_REMOVE_CUSTOMER,
          payload: phoneNum,
        });
      }
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while searching for phone number', e);
        dispatch({
          type: ACTIONS.IS_PHONE_LOADING,
          payload: {
            isPhoneLoading: { [phoneNum]: false },
          },
        });
        dispatch({
          type: ACTIONS.SET_PHONE_REMOVE_CUSTOMER,
          payload: phoneNum,
        });
      }
    }
  };

  const gotoNextStepFromPhoneNumStep = () => {
    setAppState({ isAppIdle: false });

    // check if data acquisition flow is enabled
    const { dataCaptureFlow = 'off', secondaryFieldsToCapture } = venueSettings;

    // if dataCaptureFlow is off, proceed wit normal flow
    const { isFound } = customer;
    if (isFound) {
      if (dataCaptureFlow === DATA_CAPTURE_FLOWS.REDEMPTION) {
        // if dataCaptureFlow is enabled
        // check if customer details is sufficient

        // get all mandatory and optional fields from the settings
        const allFields = Object.keys(secondaryFieldsToCapture).filter(
          (key) =>
            secondaryFieldsToCapture[key] === FIELD_OPTIONS.MANDATORY ||
            secondaryFieldsToCapture[key] === FIELD_OPTIONS.OPTIONAL
        );

        let emptyFieldsCount = allFields.length;
        // if there's a mandatory field
        // create a new object by merging the mandatory fields with the customer data
        // if there is an empty field, then show the data acquisition field
        if (allFields.length) {
          const customerData = {};

          Object.keys(customer)
            .filter((key) => allFields.indexOf(key) !== -1)
            .forEach((key) => {
              // eslint-disable-next-line no-extra-boolean-cast
              if (!!customer[key]) emptyFieldsCount -= 1;
              customerData[key] = customer[key];
            });
        }

        // should we get all optional fields before showing the popup?
        if (emptyFieldsCount > 0) {
          dispatch({
            type: ACTIONS.GOTO_STEP,
            payload: { STEP: STEPS.DATA_ACQUISITION },
          });
        } else {
          dispatch({
            type: ACTIONS.GOTO_STEP,
            payload: { STEP: STEPS.REDEEM_CAMPAIGN, idempotencyKey: uuidv4() },
          });
        }
      } else {
        dispatch({
          type: ACTIONS.GOTO_STEP,
          payload: { STEP: STEPS.REDEEM_CAMPAIGN, idempotencyKey: uuidv4() },
        });
      }
    } else {
      console.error('Debug why next step was enabled when guest was not found');
    }
  };

  const gotoRedeemCashbackStep = () => {
    dispatch({
      type: ACTIONS.GOTO_STEP,
      payload: { STEP: STEPS.REDEEM_AMOUNT },
    });
  };

  const redeemCampaignGotoConfirmHandler = async ({ campaignId }) => {
    dispatch({
      type: ACTIONS.SELECT_CAMPAIGN_AND_GOTO_CONFIRM,
      payload: {
        campaignId,
      },
    });
  };

  const redeemCampaignHandler = async ({ campaignId }) => {
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      await redeemCampaignPATCH({
        token,
        guestId: customer.id,
        campaignId,
        venueId: currentVenueId,
      });

      dispatch({
        type: ACTIONS.MARK_REDEEMABLE_CAMPAIGN_AS_REDEEMED_GOTO_REDEEM_CAMPAIGN_SUCCESS,
        payload: {
          campaignId,
        },
      });
    } catch (e) {
      // Redeem campaign failed
      console.error(`Redeem campaign failed - campaignId: ${campaignId}. DEBUG WHY!`, e);
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const updateCustomerHandler = async ({
    customerId,
    firstName,
    phone,
    ...secondaryFieldsToCapture
  }) => {
    // check
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      const customerData = { firstName, phone, ...secondaryFieldsToCapture };

      const data = await updateCustomerPATCH({
        token,
        currentVenueId,
        customerId,
        customerData,
      });

      const {
        id,
        firstName: fn,
        lastName: ln,
        phone: ph,
        email,
        birthday,
        gender,
        loyaltyAccount: {
          id: loyaltyAccountId,
          balanceAmount,
          cashbackAmount,
          redeemedAmount,
        } = {},
        currentTier = {},
      } = data;

      dispatch({
        type: ACTIONS.SET_CUSTOMER,
        payload: {
          id,
          loyaltyAccountId,
          firstName: fn,
          lastName: ln,
          phone: ph,
          email,
          gender,
          birthday: birthday || '',
          balance: balanceAmount,
          totalClaimed: cashbackAmount,
          totalRedeemed: redeemedAmount,
          currentTier,
        },
      });

      setAppState({
        globalCustomer: {
          id,
          loyaltyAccountId,
          firstName: fn,
          lastName: ln,
          phone: ph,
          email,
          gender,
          birthday: birthday || '',
          balance: balanceAmount,
          totalClaimed: cashbackAmount,
          totalRedeemed: redeemedAmount,
          currentTier,
        },
      });

      dispatch({
        type: ACTIONS.SET_DUPLICATE_EMAIL_STATUS,
        payload: { hasDuplicateEmail: false },
      });

      if (editDetailsMode) {
        // from EDIT goto back to AMOUNT
        dispatch({
          type: ACTIONS.GOTO_STEP,
          payload: {
            STEP: STEPS.REDEEM_AMOUNT,
            editDetailsMode: false,
            error: null,
          },
        });
      } else {
        // from data acq go to CAMPAIGNS list
        dispatch({
          type: ACTIONS.GOTO_STEP,
          payload: { STEP: STEPS.REDEEM_CAMPAIGN, error: null },
        });
      }
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else if (e.name === ERROR_TYPES.EmailAlreadyExistsError) {
        dispatch({
          type: ACTIONS.SET_DUPLICATE_EMAIL_STATUS,
          payload: { hasDuplicateEmail: true },
        });
      } else if (e.name === ERROR_TYPES.PhoneAlreadyExistsError) {
        dispatch({
          type: ACTIONS.SET_ERROR,
          payload: { error: e },
        });
      } else {
        // add failed
        console.error('Something blew up while updating customer', e);
      }
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const redeemAmountHandler = async (amount) => {
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      const data = await redeemAmountPOST({
        token,
        currentVenueId,
        loyaltyAccountId: customer.loyaltyAccountId,
        amount,
        customerId: customer.id,
        idempotencyKey,
      });
      const {
        id: lastTransactionId,
        amount: amountRedeemed,
        totalSpend: amountSpent,
        verifyMethod = '',
        guest: { loyaltyAccount: { id: loyaltyAccountId } = {} } = {},
      } = data;

      setAppState({
        globalCustomer: {
          id: customer.id || 0,
          loyaltyAccountId: customer.loyaltyAccountId || loyaltyAccountId,
          phone: customer.phone || '',
          firstName: customer.firstName || '',
          lastName: customer.lastName || '',
          gender: customer.gender || '',
          birthday: customer.birthday || null,
          email: customer.email || '',
          balance: parseFloat((Number(customer.balance) || 0) - Number(amountRedeemed)).toFixed(1),
          currentTier: customer.currentTier,
        },
      });

      // redeem success
      dispatch({
        type: ACTIONS.SET_AMOUNT,
        payload: {
          lastTransactionId,
          amountRedeemed,
          amountSpent,
          loyaltyAccountId,
        },
      });

      let nextStep = '';
      if (verifyMethod === REDEMPTION_SECURITY_OPTIONS.PASSWORD) {
        nextStep = STEPS.VALIDATE_PASSWORD;
      } else if (verifyMethod === REDEMPTION_SECURITY_OPTIONS.OTP_OR_PASSWORD) {
        nextStep = STEPS.VALIDATE_OTP_OR_PASSWORD;
      } else {
        nextStep = STEPS.VALIDATE_OTP;
      }

      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: nextStep },
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while redeeming the amount');
      }
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const submitOTPHandler = async (otp) => {
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      const data = await submitOTPPOST({
        token,
        currentVenueId,
        transactionId: customer.lastTransactionId,
        otp,
      });
      const {
        amount: amountRedeemed,
        guest: { loyaltyAccount: { balanceAmount: balance } = {} } = {},
      } = data;

      dispatch({
        type: ACTIONS.SET_AMOUNT,
        payload: { balance, amountRedeemed },
      });
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.REDEEM_SUCCESS },
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else if (OTP_ERRORS.indexOf(e.name) !== -1) {
        dispatch({
          type: ACTIONS.SET_OTP_ERROR,
          payload: { hasOTPError: true, errorMessage: e.message },
        });
      } else {
        // error scenario
        console.error('Something blew up while submiting OTP', e);
      }
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const resendOTPHandler = async () => {
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      await resendOTPPOST({
        token,
        currentVenueId,
        transactionId: customer.lastTransactionId,
      });

      dispatch({ type: ACTIONS.SET_RESEND_FLAG });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else if (OTP_ERRORS.indexOf(e.name) !== -1) {
        dispatch({ type: ACTIONS.SET_OTP_ERROR, payload: e.message });
      } else {
        // error scenario
        console.error('Something blew up while requesting OTP resend', e);
      }
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const redeemSuccessRedirectHandler = () => {
    resetAndRedirectToDefaultPageHandler();
  };

  const redeemCampaignSuccessRedirectHandler = () => {
    resetAndRedirectToDefaultPageHandler();
  };

  const redeemCampaignsRedirectHandler = () => {
    dispatch({
      type: ACTIONS.GOTO_STEP,
      payload: { STEP: STEPS.REDEEM_CAMPAIGN },
    });
  };

  const redeemCashbackRedirectHandler = () => {
    dispatch({
      type: ACTIONS.GOTO_STEP,
      payload: { STEP: STEPS.REDEEM_AMOUNT },
    });
  };

  const gotoEditDetailsHandler = () => {
    // GOTO EDIT STEP (DATA_ACQUISITION)
    dispatch({
      type: ACTIONS.ENABLE_EDIT_DETAILS_MODE_GOTO_DATA_ACQ_STEP,
    });
  };

  const removeErrorIndicator = () => {
    dispatch({
      type: ACTIONS.SET_OTP_ERROR,
      payload: { hasOTPError: false, errorMessage: '' },
    });
  };

  const fetchRedeemableCampaignsAndRefreshState = async () => {
    try {
      const allCampaigns = await fetchCampaignsByGuestIdGET({
        token,
        currentVenueId,
        guestId: customer.id,
      });

      const redeemableCampaigns = allCampaigns.filter((c) => c.redeemedAt === null);

      dispatch({
        type: ACTIONS.SET_REDEEMABLE_CAMPAIGNS,
        payload: {
          redeemableCampaigns,
        },
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while redeeming campaigns', e);
      }
    }
  };

  const addNoteToTransactionHandler = (note) => {
    try {
      const { lastTransactionId } = customer;

      addNoteToTransactionPOST({
        token,
        currentVenueId,
        note,
        transactionId: lastTransactionId,
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while adding notes', e);
      }
    }
  };

  const usePasswordValidation = () => {
    dispatch({
      type: ACTIONS.GOTO_STEP,
      payload: { STEP: STEPS.VALIDATE_PASSWORD },
    });
  };

  const authorizationSuccessHandler = (data) => {
    try {
      const {
        amount: amountRedeemed,
        guest: { loyaltyAccount: { balanceAmount: balance } = {} } = {},
      } = data;

      dispatch({
        type: ACTIONS.SET_AMOUNT,
        payload: { balance, amountRedeemed },
      });
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.REDEEM_SUCCESS },
      });
    } catch (e) {
      // error scenario
      console.error('Something blew up while setting amount and balance after authorization', e);
    }
  };

  const authorizationErrorHandler = (e) => {
    console.error('authorizationErrorHandler: ', e);
  };

  React.useEffect(() => {
    if (customer.id) fetchRedeemableCampaignsAndRefreshState();
    // eslint-disable-next-line
  }, [customer.id]);

  return {
    step,
    partner,
    customer,
    locale,
    errorMessage,
    isLoading,
    venueSettings,
    editDetailsMode,
    error,
    resetDataHandler,
    resetAndRedirectToDefaultPageHandler,
    phoneNumberSearchHandler,
    gotoNextStepFromPhoneNumStep,
    gotoRedeemCashbackStep,
    redeemCampaignGotoConfirmHandler,
    redeemCampaignHandler,
    redeemAmountHandler,
    submitOTPHandler,
    resendOTPHandler,
    redeemSuccessRedirectHandler,
    redeemCampaignSuccessRedirectHandler,
    redeemCampaignsRedirectHandler,
    redeemCashbackRedirectHandler,
    gotoEditDetailsHandler,
    removeErrorIndicator,
    updateCustomerHandler,
    selectedRedeemableCampaign,
    selectedRedeemableCampaignRedeemed,
    addNoteToTransactionHandler,
    isPhoneLoading,
    usePasswordValidation,
    authorizationSuccessHandler,
    authorizationErrorHandler,
  };
};

export default useWrapper;
