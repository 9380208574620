import { REACT_APP_INTERCOM_APP_ID } from 'constants/app-env';
import Cookies from 'js-cookie';

const COOKIE_EXPIRY_DAYS = 365;
const LOYALTY_TOKEN = 'loyalty_token';
const LOYALTY_CURRENT_VENUE_ID = 'loyalty_currentVenueId';
const LOYALTY_USERNAME = 'loyalty_username';
const INTERCOM_SESSION = `intercom-session-${REACT_APP_INTERCOM_APP_ID}`;
const DOMAIN_NAME = '.letsumai.com';
export const savePartnerSession = ({ username, token, currentVenueId } = {}) => {
  if (token) Cookies.set(LOYALTY_TOKEN, token, { expires: COOKIE_EXPIRY_DAYS });
  if (currentVenueId)
    Cookies.set(LOYALTY_CURRENT_VENUE_ID, currentVenueId, {
      expires: COOKIE_EXPIRY_DAYS,
    });
  if (username) Cookies.set(LOYALTY_USERNAME, username, { expires: COOKIE_EXPIRY_DAYS });
};

export const getPartnerSession = () => {
  const token = Cookies.get(LOYALTY_TOKEN);
  const currentVenueId = Cookies.get(LOYALTY_CURRENT_VENUE_ID);
  const username = Cookies.get(LOYALTY_USERNAME);
  return {
    username,
    token,
    currentVenueId,
  };
};

export const removePartnerSession = ({ all = true } = {}) => {
  if (all) {
    Cookies.remove(LOYALTY_TOKEN);
    Cookies.remove(LOYALTY_CURRENT_VENUE_ID);
    Cookies.remove(LOYALTY_USERNAME);
    Cookies.remove(INTERCOM_SESSION, { domain: DOMAIN_NAME });
  } else {
    // by default remove only token during logout
    Cookies.remove(LOYALTY_TOKEN);
  }
};

export const removeAllCookies = () => {
  const allCookies = Cookies.get();
  Object.keys(allCookies).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });

  // eslint-disable-next-line no-console
  console.debug('All cookies removed');
};
