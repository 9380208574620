import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getViewParam, getViewFromPath } from 'util/url';
import APP_ACCESS from 'constants/app-access';
import APP_VIEW from 'constants/app-view';
import APP_ENV, { REACT_APP_ENVIRONMENT } from 'constants/app-env';
import { semverGreaterThan } from 'util/helpers';
import { fetchLatestAppVersion as fetchLatestAppVersionGET } from 'api/version';
import packageJson from '../../package.json';

/*
  - initialize app with url params (view, token, etc)
  - default view is capture
*/
const initialContext = {
  version: packageJson.version,
  hasLoaded: false,
  errorOnLoad: false,
  access: APP_ACCESS.CAPTURE,
  isLoading: false,
  isAppIdle: true,
  shouldReloadAppOnPageIdle: false,
  globalCustomer: {
    id: 0,
    loyaltyAccountId: 0,
    phone: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthday: null,
    email: '',
    balance: 0,
  },
};

const AppContext = React.createContext(initialContext);

function AppProvider({ children }) {
  const [app, setAppState] = useState(initialContext);

  // eslint-disable-next-line no-shadow
  function setAppStateImmutably(app) {
    setAppState((s) => ({ ...s, ...app }));
  }

  // executes before app is initialized
  useEffect(() => {
    // get url param

    let view = APP_VIEW.CAPTURE;

    const viewFromParam = getViewParam();

    if (viewFromParam) {
      view = viewFromParam;
      // eslint-disable-next-line no-console
      console.debug('Setting view from view param');
    } else {
      const viewFromPath = getViewFromPath();

      if (viewFromPath) {
        view = viewFromPath;
        // eslint-disable-next-line no-console
        console.debug('Setting view from url path');
      }
    }

    const access = view === APP_VIEW.REPORTS ? APP_ACCESS.MANAGER : APP_ACCESS.CAPTURE;
    setAppStateImmutably({ access });
  }, []);

  useEffect(() => {
    // force refresh app if a new version is available
    if (app.isAppIdle && REACT_APP_ENVIRONMENT !== APP_ENV.LOCAL) {
      fetchLatestAppVersionGET()
        .then((data) => {
          const latestVersion = data.appVersion;
          const currentVersion = app.version;

          const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
          if (shouldForceRefresh) {
            // eslint-disable-next-line no-console
            console.debug(`We have a new version - ${latestVersion}. Going to force refresh`);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.error('Error occured while fetching Latest App Version', err);
        });
    }
    // eslint-disable-next-line
  }, [app.isAppIdle]);

  return (
    <AppContext.Provider
      // eslint-disable-next-line
      value={{
        app,
        setAppState: setAppStateImmutably,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
export { AppContext };
