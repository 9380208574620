import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/ModalWrapper';
import AmountPad from 'components/Amount/AmountPad';
import CustomerDetails from 'components/CustomerDetails';
import * as Styles from './DeductAmountStep.styles';

const DeductAmountStep = (props) => {
  const {
    customer: { firstName, lastName, phone, balance, email, gender, birthday, currentTier } = {},
    dataCaptureFlow,
    secondaryFieldsToDisplay = {},
    deductAmountHandler,
    resetModalHandler,
    locale,
  } = props;
  const [t] = useTranslation();
  const renderCustomer = () => (
    <CustomerDetails
      Styles={Styles}
      customer={{
        firstName,
        lastName,
        phone,
        balance,
        gender,
        email,
        birthday,
        currentTier,
      }}
      dataCaptureFlow={dataCaptureFlow}
      secondaryFieldsToDisplay={secondaryFieldsToDisplay}
      locale={locale}
      amountLabel={t('available-credit')}
      popOverLinks={['claim', 'redeemAmount', 'redeemCampaign', 'add']}
    />
  );

  return (
    <div className="deduct-loyalty-step">
      <ModalWrapper wrapperSelector={'.deduct-loyalty-step'} resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.cusContainer}>{renderCustomer()}</div>
          <AmountPad
            Styles={Styles}
            header={t('enter-the-amount-to-deduct')}
            currencyDenomination={locale.currencyDenomination}
            isAmountGreaterThanBalance={(number) => Number(number) > Number(balance)}
            isAmountCTAEnabled={(number, isGreaterThanBalance) =>
              Number(number) > 0 && !isGreaterThanBalance
            }
            errMsg={t('there-is-not-enough-credit-to-deduct')}
            amountHandler={deductAmountHandler}
            amountButton={t('deduct-credit')}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};

DeductAmountStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    currentTier: PropTypes.object,
  }),
  dataCaptureFlow: PropTypes.string.isRequired,
  secondaryFieldsToDisplay: PropTypes.object,
  deductAmountHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
};

export default DeductAmountStep;
