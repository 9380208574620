import { css } from '@emotion/react';

export const wrapper = css`
  // to couterfeit modal default padding 2rem
  margin: -2rem;
  min-height: calc(100vh - (3rem * 2));
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
`;

// customer container styles go below
export const cusContainer = css`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const cusContent = css`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3rem;
  width: 100%;
`;

export const cusDetailsWrapper = css`
  padding-top: 1rem;
  width: 75%;
`;

export const inputWrapper = css`
  padding: 0.5rem 0;
`;

export const rowWrapper = css`
  display: flex;
  flex-wrap: wrap;

  > div:first-of-type {
    margin-right: 2.75rem;
  }
`;

export const entryWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 0;
`;

export const entryLabel = css`
  color: var(--text-grey);
`;

export const entryContent = css`
  font-weight: bold;
  letter-spacing: 0.2px;
`;

export const placeholderData = css`
  color: var(--text-grey);
  font-weight: normal;
`;

export const cusFooter = css`
  min-height: 6rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
`;

// input container styles go below
export const inputContainer = css`
  background: var(--color-popupBgGrey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const overlayDiv = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  background: white;
  z-index: 2;
`;

export const inputContent = css`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1rem 0;
`;

export const detailsTitleSection = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const sectionDesc = css`
  font-weight: bold;
  font-size: 1.375rem;
  padding-bottom: 0.5rem;
  text-align: center;
`;

export const detailsTitle = css`
  ${sectionDesc}
  flex: 1;
  text-align: left;
  padding-bottom: 0;
`;

export const amount = css`
  font-size: 1.5rem;
  font-weight: 400;
  padding: 1rem 0;
`;

export const inputFooter = css`
  min-height: 6rem;
`;

export const btn = css`
  border: none;
  outline: none;
  appearance: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const CTA = css`
  ${btn}
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  min-width: 16rem;
  min-height: 3.25rem;
`;

export const disabledCTA = css`
  ${CTA}
  background-color: var(--color-grey);
  cursor: auto;
`;

export const editDetailsBtn = css`
  ${btn}
  border-radius: 3px;
  background-color: transparent;
  color: var(--color-primary);
  font-size: 0.75rem;
  font-weight: 500;
  min-height: 2rem;
  box-shadow: -2px 2px 4px 1px rgba(0, 0, 0, 0.125);
`;

const baseOverlayStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 5px;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.75);
`;

export const loadingOverlay = css`
  ${baseOverlayStyles}
`;

export const loadingOverlayHidden = css`
  ${baseOverlayStyles}
  display: none;
`;

export const nameError = css`
  margin-top: -0.75rem;
  font-size: 1rem;

  text-align: left;
  font-weight: 400;
  color: var(--color-error);

  animation-name: slide-down;
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
`;

export const capitalize = css`
  text-transform: capitalize;
`;

export const dropdownMenuTriggerBtn = css`
  ${btn}
  border-radius: 3px;
  background-color: transparent;
  color: var(--color-primary);
  font-size: 0.75rem;
  font-weight: 500;
  min-height: 2rem;
  box-shadow: -2px 2px 4px 1px rgba(0, 0, 0, 0.125);
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const dropdownMenu = css`
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    margin-bottom: 0;
    a {
      display: block;
      cursor: pointer;
      padding: 0.75rem;
      cursor: pointer;
      text-decoration: none;

      &:focus,
      &:active,
      &:hover {
        color: var(--color-primary);
      }
    }
  }
`;
