import { css } from '@emotion/react';

export const wrapper = css`
  min-height: calc(100vh - (3rem * 2) - (1.5rem * 2));
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
`;

export const contentContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
`;

export const customerDetails = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0 2rem;
`;

export const entryWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1rem;
`;

export const entryLabel = css`
  color: var(--text-grey);
`;

export const entryContent = css`
  font-weight: bold;
  letter-spacing: 0.2px;
`;

export const thankYou = css`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const waiterText = css`
  padding: 0.5rem 0;
`;

export const CTA = css`
  border: none;
  outline: none;
  appearance: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  margin: 1rem 0;
  padding: 0.75rem 2rem;
  border-radius: 5px;
  cursor: pointer;
`;

export const coinsIcon = css`
  margin-bottom: 1rem;

  svg {
    .cls-1 {
      opacity: 0.8;
    }
    .cls-2 {
      fill: var(--color-primary);
    }
    .cls-3 {
      fill: #fff;
      stroke: var(--color-primary);
      stroke-miterlimit: 10;
      stroke-width: 2.5px;
    }
    .cls-4 {
      stroke: none;
    }
    .cls-5 {
      fill: none;
    }
  }
`;
