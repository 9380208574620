// import { darken } from 'polished';

const colors = {
  loyaltyAqua: '#169a89',
  loyaltyAquaFaded: '#c9e7e1',
  loyaltyGrey: '#919191',
  loyaltyRed: '#ea6464',
  loyaltyGreyFaded: '#dfdfdf',
  borderGrey: '#d8d8d8',
  bgWhite: '#eee',
  bgGrey: '#f8f9f8',
  textBlack: '#333',
  textWhite: '#e7e7e7',
  popupBgGrey: '#f3f3f3',
};

const defaultTheme = {
  colors: {
    primary: colors.loyaltyAqua,
    alternate: colors.loyaltyAquaFaded,
    error: colors.loyaltyRed,
    darkGrey: colors.loyaltyGrey,
    grey: colors.loyaltyGreyFaded,
    bg: colors.bgWhite,
    bgGrey: colors.bgGrey,
    popupBgGrey: colors.popupBgGrey,
  },
  text: {
    primary: colors.textBlack,
    alternate: colors.textWhite,
    grey: colors.loyaltyGrey,
  },
  border: {
    primary: colors.loyaltyAquaFaded,
    alternate: colors.borderGrey,
    error: colors.loyaltyRed,
  },
  dimensions: {
    headerHeight: '0rem',
    footerHeight: '0rem',
  },
};

export { defaultTheme };
