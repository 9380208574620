import { css } from '@emotion/react';

export const wrapper = css`
  min-height: calc(100vh - (3rem * 2) - (1.5rem * 2));
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
`;

export const contentContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
`;

export const customerDetails = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.5rem 0;
`;

export const entryWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1rem;
`;

export const entryLabel = css`
  color: var(--text-grey);
  text-transform: capitalize;
`;

export const entryContent = css`
  font-weight: bold;
`;

export const successText = css`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const successSubText = css`
  padding: 0.5rem 0;
`;

export const CTA = css`
  border: none;
  outline: none;
  appearance: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  margin: 2rem 0;
  padding: 0.75rem 2rem;
  border-radius: 5px;
  min-width: 12.5rem;
  cursor: pointer;
`;

export const redirectWrapper = css`
  display: flex;

  > * {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1.2rem 0;
  }

  a {
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const linkDanger = css`
  color: var(--color-error) !important;
`;

export const roundedHyphen = css`
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  position: relative;
  margin: 0 0.375rem;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '-';
    padding-bottom: 3px;
  }
`;

export const roundedHyphenDanger = css`
  ${roundedHyphen}
  border-color: var(--color-error) !important;
`;

export const coinsWithCheckIcon = css`
  margin-bottom: 1rem;

  svg {
    .cls-1 {
      opacity: 0.8;
    }
    .cls-2,
    .cls-4 {
      fill: var(--color-primary);
    }
    .cls-3 {
      fill: #fff;
      stroke-width: 2.5px;
    }
    .cls-3,
    .cls-4 {
      stroke: var(--color-primary);
      stroke-miterlimit: 10;
    }
    .cls-5 {
      stroke: none;
    }
    .cls-6 {
      fill: none;
    }
  }
`;
