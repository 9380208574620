import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OPERATIONAL_PERMISSIONS } from 'constants/operational-permission';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import ModalWrapper from 'components/ModalWrapper';
import { CoinsWithCheck, AddIcon, DeductIcon, CheckIcon } from 'components/Icons';
import AddNote from 'components/AddNote';
import Amount from 'components/Amount';
import ProtectedElement from 'components/ProtectedElement';
import { STEPS as REDEEM_STEPS } from 'pages/CapturePage/components/Redeem/Redeem.useWrapper';
import { STEPS as DEDUCT_STEPS } from 'pages/CapturePage/components/Deduct/Deduct.useWrapper';
import { STEPS as ADD_STEPS } from 'pages/CapturePage/components/Add/Add.useWrapper';
import * as Styles from './ClaimSuccessStep.styles';

const ClaimSuccessStep = (props) => {
  const [t] = useTranslation();
  const {
    customer: { firstName, lastName, phone, amountSpent, amountClaimed } = {},
    locale,
    claimSuccessRedirectHandler,
    addNoteToTransactionHandler,
    resetModalHandler,
  } = props;

  const [note, setTransactionNote] = React.useState('');
  const noteChangeHandler = (newNote) => {
    setTransactionNote(newNote);
  };

  const addNoteToTransaction = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
  };

  const doneButtonHandler = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
    claimSuccessRedirectHandler();
  };

  return (
    <div className="claim-success-step">
      <ModalWrapper wrapperSelector={'.claim-success-step'} resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.contentContainer}>
            <div css={Styles.coinsWithCheckIcon}>
              <CoinsWithCheck />
            </div>
            <div css={Styles.successText}>{t('credit-added-successfully')}</div>
            <div css={Styles.successSubText}>
              {t('name-will-receive-credits-in-a-moment', { firstName })}
            </div>

            <div css={Styles.customerDetails}>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('name')}</div>
                <div css={Styles.entryContent}>
                  {firstName} {lastName}
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('phone-number')}</div>
                <div css={Styles.entryContent}>{formatNumber(phone)}</div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('total-spend')}</div>
                <div css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={amountSpent} />
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('cashback')}</div>
                <div css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={amountClaimed} />
                </div>
              </div>
            </div>

            <AddNote noteChangeHandler={noteChangeHandler} />

            <button
              id="done-button"
              css={Styles.CTA}
              type="button"
              {...assignEventListener(doneButtonHandler)}
            >
              {t('done')}
            </button>
            <ProtectedElement allowedPermission={OPERATIONAL_PERMISSIONS.LOYALTY_TRANSACTIONS}>
              <div css={Styles.redirectWrapper}>
                <Link
                  onClick={addNoteToTransaction}
                  to={`/capture/redeem?step=${REDEEM_STEPS.REDEEM_AMOUNT}`}
                >
                  <CheckIcon />
                  <span>{t('also-redeeming-credit')}</span>
                </Link>

                <div>{t('or')}</div>

                <Link
                  onClick={addNoteToTransaction}
                  to={`/capture/redeem?step=${REDEEM_STEPS.REDEEM_CAMPAIGN}`}
                >
                  <AddIcon />
                  <span>{t('want-to-redeem-a-campaign')}</span>
                </Link>
              </div>
            </ProtectedElement>
            <ProtectedElement
              allowedPermission={OPERATIONAL_PERMISSIONS.LOYALTY_ADDITIONS_DEDUCTIONS}
            >
              <div css={Styles.redirectWrapper}>
                <Link
                  onClick={addNoteToTransaction}
                  css={Styles.linkDanger}
                  to={`/capture/deduct?step=${DEDUCT_STEPS.DEDUCT_AMOUNT}`}
                >
                  <DeductIcon />
                  <span>{t('want-to-deduct-credit')}</span>
                </Link>

                <div>{t('or')}</div>

                <Link
                  onClick={addNoteToTransaction}
                  to={`/capture/add?step=${ADD_STEPS.ADD_AMOUNT}`}
                >
                  <AddIcon />
                  <span>{t('want-to-extra-credit')}</span>
                </Link>
              </div>
            </ProtectedElement>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

ClaimSuccessStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    amountSpent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    amountClaimed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  claimSuccessRedirectHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  addNoteToTransactionHandler: PropTypes.func,
};

export default ClaimSuccessStep;
