import { css } from '@emotion/react';
import iconDropdown from 'images/chevron-dropdown-grey.svg';

export const wrapper = css`
  // to couterfeit modal default padding 2rem
  margin: -2rem;
  min-height: calc(100vh - (3rem * 2));
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
`;

// customer container styles go below
export const cusContainer = css`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const cusContent = css`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3rem;
  width: 100%;
`;

export const sectionDesc = css`
  font-weight: bold;
  font-size: 1.375rem;
  padding-bottom: 0.5rem;
  text-align: left;
`;

export const entryWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 0;
`;

export const entryLabel = css`
  color: var(--text-grey);
`;

export const entryContent = css`
  letter-spacing: 0.2px;

  input {
    font-weight: bold;
    font-size: 1.15rem;
  }
`;

export const fieldsWrapper = css`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 0.5rem;
`;

const inputBaseStyles = css`
  padding: 0.5rem 0;
  width: 40%;
  margin: 0 1rem;
`;

export const inputWrapper = css`
  ${inputBaseStyles}
`;

export const inputWrapperWithError = css`
  ${inputBaseStyles}
  input {
    border-bottom-color: var(--color-error);
  }
`;

export const inputLabel = css`
  text-align: left;
  color: var(--text-grey);
`;

export const datePickerWrapper = css`
  width: 100%;

  li{
    margin: 0;
  }
  
  }
`;

export const datePickerInput = css`
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0 0 1rem;
  background: none;
  border-bottom: 1px solid var(--text-grey);
  width: 100%;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const selectBoxBaseStyles = css`
  padding: 1px 0rem;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);

  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url(${iconDropdown}) transparent no-repeat center right !important; /* !important used for overriding all other customisations */
  background: url(${iconDropdown}) transparent no-repeat center right !important; /* Better placement regardless of input width */
  background-size: 1.25rem !important;
  width: 100%;
  border-bottom: 1px solid var(--text-grey);
`;

export const selectBox = ({ noValue }) => css`
  ${selectBoxBaseStyles}

  ${noValue &&
  `
  height: 30px;
  `}
`;

export const selectPopover = css`
  width: 180px;
  font-size: 1rem;
  font-weight: 400;
  max-height: 40vh;
  overflow-y: auto;

  & > * {
    border: 0;
    background-color: #fff;
    width: 100%;
    color: var(--text-primary);
    border-bottom: 1px solid #d9d9d9;
    padding: 1rem;
    cursor: pointer;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }

    :first-of-type {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    :last-of-type {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-bottom: none;
    }
  }
`;

export const cusFooter = css`
  min-height: 6rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
`;

export const CTA = css`
  border: none;
  outline: none;
  appearance: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  min-width: 16rem;
  min-height: 3.25rem;
  border-radius: 5px;
  cursor: pointer;
`;

export const disabledCTA = css`
  ${CTA}
  background-color: var(--color-grey);
  cursor: auto;
`;

const baseOverlayStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 5px;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.75);
`;

export const loadingOverlay = css`
  ${baseOverlayStyles}
`;

export const loadingOverlayHidden = css`
  ${baseOverlayStyles}
  display: none;
`;

export const errMsg = css`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-error);
  margin-top: -0.875rem;
`;
