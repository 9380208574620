import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/ModalWrapper';
import { STEPS as CLAIM_STEPS } from 'pages/CapturePage/components/Claim/Claim.useWrapper';
import { CoinsWithCheck, CheckIcon, AddIcon } from 'components/Icons';
import AddNote from 'components/AddNote';
import Amount from 'components/Amount';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import * as Styles from './RedeemSuccessStep.styles';

const RedeemSuccessStep = (props) => {
  const {
    customer: { firstName, lastName, phone, balance, amountRedeemed } = {},
    redeemSuccessRedirectHandler,
    redeemCampaignsRedirectHandler,
    addNoteToTransactionHandler,
    resetModalHandler,
    locale,
  } = props;
  const [t] = useTranslation();
  const [note, setTransactionNote] = React.useState('');
  const noteChangeHandler = (newNote) => {
    setTransactionNote(newNote);
  };

  const addNoteToTransaction = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
  };

  const doneButtonHandler = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
    redeemSuccessRedirectHandler();
  };

  const redeemCampaignHandler = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
    redeemCampaignsRedirectHandler();
  };

  return (
    <div className="redeem-success-step">
      <ModalWrapper wrapperSelector=".redeem-success-step" resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.contentContainer}>
            <div css={Styles.coinsWithCheckIcon}>
              <CoinsWithCheck />
            </div>
            <div css={Styles.successText}>{t('credit-redeemed-successfully')}</div>
            <div css={Styles.successSubText}>
              {t('credit-has-been-redeemed-from-account', { firstName })}
            </div>

            <div css={Styles.customerDetails}>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('name')}</div>
                <div css={Styles.entryContent}>
                  {firstName} {lastName}
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('phone-number')}</div>
                <div css={Styles.entryContent}>{formatNumber(phone)}</div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('redeemed')}</div>
                <div css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={amountRedeemed} />
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('balance')}</div>
                <div css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={balance} />
                </div>
              </div>
            </div>

            <AddNote noteChangeHandler={noteChangeHandler} />

            <button
              id="done-button"
              css={Styles.CTA}
              type="button"
              {...assignEventListener(doneButtonHandler)}
            >
              {t('done')}
            </button>

            <div css={Styles.redirectWrapper}>
              <Link
                onClick={addNoteToTransaction}
                to={`/capture/claim?step=${CLAIM_STEPS.CLAIM_LOYALTY}`}
              >
                <CheckIcon />
                <span>{t('also-claiming-cashback')}</span>
              </Link>

              <div>{t('or')}</div>
              {/* eslint-disable-next-line */}
              <a onClick={redeemCampaignHandler}>
                <AddIcon />
                <span>{t('want-to-redeem-a-campaign')}</span>
              </a>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

RedeemSuccessStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    amountRedeemed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  redeemSuccessRedirectHandler: PropTypes.func.isRequired,
  redeemCampaignsRedirectHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  addNoteToTransactionHandler: PropTypes.func,
};

export default RedeemSuccessStep;
