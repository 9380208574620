import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import { humanizeCampaignName } from 'util/campaign-helper';
import ROUTES from 'constants/routes';
import ModalWrapper from 'components/ModalWrapper';
import { STEPS as CLAIM_STEPS } from 'pages/CapturePage/components/Claim/Claim.useWrapper';
import { CoinsWithCheck, CheckIcon, AddIcon } from 'components/Icons';
import AddNote from 'components/AddNote';
import Amount from 'components/Amount';
import * as Styles from './RedeemCampaignSuccessStep.styles';

const RedeemCampaignSuccessStep = (props) => {
  const {
    customer: { firstName, lastName, phone, balance } = {},
    selectedRedeemableCampaign = {},
    redeemCampaignSuccessRedirectHandler,
    redeemCashbackRedirectHandler,
    addNoteToTransactionHandler,
    resetModalHandler,
    locale,
  } = props;
  const [t] = useTranslation();
  const [note, setTransactionNote] = React.useState('');
  const noteChangeHandler = (newNote) => {
    setTransactionNote(newNote);
  };

  const addNoteToTransaction = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
  };

  const doneButtonHandler = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
    redeemCampaignSuccessRedirectHandler();
  };

  const redeemCashbackHandler = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
    redeemCashbackRedirectHandler();
  };

  return (
    <div className="redeem-success-step">
      <ModalWrapper wrapperSelector=".redeem-success-step" resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.contentContainer}>
            <div css={Styles.coinsWithCheckIcon}>
              <CoinsWithCheck />
            </div>
            <div css={Styles.successText}>{t('campaign-redeemed-successfully')}</div>
            <div css={Styles.successSubText}>
              {t('campaign-name-has-been-redeemed-from-account', {
                loyaltyCampaignName:
                  humanizeCampaignName(selectedRedeemableCampaign.loyaltyCampaignName) || '',
                firstName,
              })}
            </div>

            <div css={Styles.campaignNameAlias}>
              {t('loyalty-campaign-name', {
                loyaltyCampaignName: selectedRedeemableCampaign?.loyaltyCampaignName || '',
              })}
            </div>

            <div css={Styles.customerDetails}>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('name')}</div>
                <div css={Styles.entryContent}>
                  {firstName} {lastName}
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('phone-number')}</div>
                <div css={Styles.entryContent}>{formatNumber(phone)}</div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('redeemed')}</div>
                <div css={Styles.entryContent}>
                  {humanizeCampaignName(selectedRedeemableCampaign.loyaltyCampaignName) || ''}
                  {t('loyalty-campaign-name', {
                    loyaltyCampaignName: selectedRedeemableCampaign?.loyaltyCampaignName || '',
                  })}
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('balance')}</div>
                <div css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={balance} />
                </div>
              </div>
            </div>

            <AddNote noteChangeHandler={noteChangeHandler} />

            <button
              id="done-button"
              css={Styles.CTA}
              type="button"
              {...assignEventListener(doneButtonHandler)}
            >
              {t('done')}
            </button>

            <div css={Styles.redirectWrapper}>
              {/* eslint-disable-next-line */}
              <a onClick={redeemCashbackHandler}>
                <CheckIcon />
                <span>{t('also-redeeming-cash')}</span>
              </a>

              <div css={Styles.or}>{t('or')}</div>

              <Link
                onClick={addNoteToTransaction}
                to={`${ROUTES.CAPTURE}/claim?step=${CLAIM_STEPS.CLAIM_LOYALTY}`}
              >
                <AddIcon />
                <span>{t('claiming-cashback')}</span>
              </Link>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

RedeemCampaignSuccessStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    amountRedeemed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  selectedRedeemableCampaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    loyaltyCampaignName: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    expiredAt: PropTypes.string.isRequired,
    redeemedAt: PropTypes.string,
  }).isRequired,
  redeemCashbackRedirectHandler: PropTypes.func.isRequired,
  redeemCampaignSuccessRedirectHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  addNoteToTransactionHandler: PropTypes.func,
};

export default RedeemCampaignSuccessStep;
