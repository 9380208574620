import { REACT_APP_SENTRY_DSN, isProductionEnv, REACT_APP_ENVIRONMENT } from 'constants/app-env';
import * as Sentry from '@sentry/react';
import packageJson from '../../package.json';

export const captureException = (error, exceptionOptions) =>
  Sentry.captureException(error, {
    tags: {
      environment: REACT_APP_ENVIRONMENT,
    },
    ...exceptionOptions,
  });

export const initSentry = () =>
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    release: `loyalty@${packageJson.version}`,
    environment: `loyalty@${REACT_APP_ENVIRONMENT}`,
    ignoreErrors: [
      'Unprocessable Entity',
      /SecurityError: The operation is insecure.*/,
      /The operation is insecure.*/,
      // Network errors such as going offline or being blocked by a proxy
      'Failed to fetch',
      // Random plugins/extensions
      'top.GLOBALS',
    ],
    denyUrls: [
      // Firefox extensions
      /^resource:\/\//i,
      // Chrome extensions
      /^extensions\//i,
      /^chrome:\/\//i,
    ],
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false,
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: isProductionEnv() ? 0.1 : 0.6,
    // Capture Replay for 5% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  });
