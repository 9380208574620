import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import { HELP_CHAT_INTERCOM_ID } from 'constants/app-const';
import { openHelpCenterLink } from 'util/url';
import { ReactComponent as HelpIcon } from 'images/help-icon.svg';
import helpPopoverIcon from 'images/help-popover-icon.svg';
import chatIcon from 'images/chat-popover-icon.svg';
import * as styles from './HelpPopover.styles';

const HelpPopover = ({ colored = false }) => {
  const [t] = useTranslation();
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Popup
      open={showPopover}
      onOpenChange={(v) => {
        setShowPopover(v);
      }}
      placement="topRight"
      triggerElement={
        <div css={styles.helpButton({ colored })}>
          <HelpIcon />
          <span>{t('help')}</span>
        </div>
      }
    >
      {/* eslint-disable-next-line */}
      <div
        onClick={() => {
          setShowPopover(false);
        }}
        css={styles.helpPopover}
      >
        {/* eslint-disable-next-line */}
        <a
          className="help-popover-item"
          rel="noopener noreferrer"
          onClick={() => {
            openHelpCenterLink();
          }}
        >
          <img src={helpPopoverIcon} alt="help-center" />
          <span>{t('help-center')}</span>
        </a>
        {/* eslint-disable-next-line */}
        <a className="help-popover-item" id={HELP_CHAT_INTERCOM_ID}>
          <img src={chatIcon} alt="chat" />
          <span>{t('chat-with-us')}</span>
        </a>
      </div>
    </Popup>
  );
};

HelpPopover.propTypes = {
  colored: PropTypes.bool,
};

export default HelpPopover;
