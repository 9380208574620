import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ModalWrapper from 'components/ModalWrapper';
import { CoinsWithCheck } from 'components/Icons';
import AddNote from 'components/AddNote';
import Amount from 'components/Amount';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import * as Styles from './AddSuccessStep.styles';

const AddSuccessStep = (props) => {
  const {
    customer: { firstName, lastName, phone, balance, amountAdded } = {},
    addSuccessRedirectHandler,
    resetModalHandler,
    addNoteToTransactionHandler,
    locale,
  } = props;
  const [t] = useTranslation();
  const [note, setTransactionNote] = React.useState('');
  const noteChangeHandler = (newNote) => {
    setTransactionNote(newNote);
  };

  const doneButtonHandler = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
    addSuccessRedirectHandler();
  };

  return (
    <div className="add-success-step">
      <ModalWrapper wrapperSelector={'.add-success-step'} resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.contentContainer}>
            <div css={Styles.coinsWithCheckIcon}>
              <CoinsWithCheck />
            </div>
            <div css={Styles.successText}>{t('credit-added-successfully')}</div>
            <div css={Styles.successSubText}>
              {t('credit-has-been-added-to-account', { firstName })}
            </div>

            <div css={Styles.customerDetails}>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('name')}</div>
                <div css={Styles.entryContent}>
                  {firstName} {lastName}
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('phone-number')}</div>
                <div css={Styles.entryContent}>{formatNumber(phone)}</div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('added')}</div>
                <div css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={amountAdded} />
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('balance')}</div>
                <div css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={balance} />
                </div>
              </div>
            </div>

            <AddNote noteChangeHandler={noteChangeHandler} />

            <button
              id="done-button"
              css={Styles.CTA}
              type="button"
              {...assignEventListener(doneButtonHandler)}
            >
              {t('done')}
            </button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

AddSuccessStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    amountAdded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  addSuccessRedirectHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  addNoteToTransactionHandler: PropTypes.func,
};

export default AddSuccessStep;
